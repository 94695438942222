import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import Popup from "reactjs-popup";
import "./App.css";
function App() {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState({});
  const [selectedModules, setSelectedModules] = useState({});
  const [selectedPflicht, setSelectedPflicht] = useState(0);
  const [selectedWahl, setSelectedWahl] = useState(0);
  const [sumOfPflicht, setSumOfPflicht] = useState(0);
  const [sumOfFree, setSumOfFree] = useState(0);
  const [courseTypes, setCourseTypes] = useState({});
  const ref = useRef(null);
  const closePopup = () => ref.current.close();
  const kursRef = useRef();
  const typRef = useRef();
  const ectsRef = useRef();
  const semesterRef = useRef();
  const [w1, setW1] = useState(0);
  const [w2, setW2] = useState(0);
  const [w3, setW3] = useState(0);
  const [w4, setW4] = useState(0);
  const [w5, setW5] = useState(0);
  const [w6, setW6] = useState(0);
  const [wahl, setWahl] = useState([{}]);
  const [freeCourses, setFreeCourses] = useState([{}]);
  const totalects = 180;
  const totalpflicht = 144;
  const totalwhal = 24;
  const totalfree = 12;
  const handleTypeChange = (courseKey, newType) => {
    setCourseTypes((prevTypes) => ({
      ...prevTypes,
      [courseKey]: newType,
    }));
    closePopup();
  };
  const handleAddCourse = () => {
    const kursValue = kursRef.current.value;
    const ectsValue = ectsRef.current.value;
    if (!kursValue || !ectsValue) {
      alert("Please fill in both 'Kurs' and 'Ects' fields.");
      return;
    }
    if (isNaN(ectsValue) || ectsValue.trim() === "") {
      alert("'Ects' must be a Number");
      return;
    }
    const newCourse = {
      Kurs: kursValue,
      TYP: typRef.current.value || "-",
      ECTS: Number(ectsValue),
      Semester: semesterRef.current.value || "-",
    };

    setFreeCourses((prevCourses) => [...prevCourses, newCourse]);

    // Clear the input fields after adding
    kursRef.current.value = "";
    typRef.current.value = "";
    ectsRef.current.value = "";
    semesterRef.current.value = "";
    setSumOfFree(sumOfFree + newCourse.ECTS);
    closePopup();
  };
  // Create a function to generate the list
  const createPositiveValueList = () => {
    // Array of all the states with their labels
    const allValues = [
      { label: "w1", value: w1 },
      { label: "w2", value: w2 },
      { label: "w3", value: w3 },
      { label: "w4", value: w4 },
      { label: "w5", value: w5 },
      { label: "w6", value: w6 },
    ];

    // Filter out the values that are greater than 0
    setWahl(allValues.filter((item) => item.value > 0));
  };

  // Function to handle module checkbox change
  const handleModuleCheck = (module, isChecked) => {
    // Update the selectedModules state
    setSelectedModules((prev) => ({ ...prev, [module.moduleName]: isChecked }));
    // Check/Uncheck all the courses under this module
    module.courses.forEach((modd) => {
      handleCheck(modd, isChecked);
    });
  };

  // Function to calculate the sum of selected ECTS for a specific module
  const calculateModuleECTS = (module) => {
    return module.courses.reduce((sum, course) => {
      const courseKey = getCourseKey(course);
      if (selectedCourses.hasOwnProperty(courseKey)) {
        return sum + Number(selectedCourses[courseKey]);
      }

      return sum;
    }, 0);
  };

  // Function to determine if all courses in a module are selected
  const areAllModuleCoursesSelected = (module) => {
    return module.courses.every((modd) =>
      selectedCourses.hasOwnProperty(getCourseKey(modd))
    );
  };
  useEffect(() => {
    const positiveValues = createPositiveValueList();
  }, [w1, w2, w3, w4, w5, w6]); // The useEffect depends on all the state variables

  const sumOfModule = (mod) => {
    switch (mod.mn) {
      case "W1":
        setW1(calculateModuleECTS(mod));
      case "W2":
        setW2(calculateModuleECTS(mod));
      case "W3":
        setW3(calculateModuleECTS(mod));
      case "W4":
        setW4(calculateModuleECTS(mod));
      case "W5":
        setW5(calculateModuleECTS(mod));
      case "W6":
        setW6(calculateModuleECTS(mod));
    }
  };

  function updateSumOfPflichtECTS() {
    const total = courses.slice(0, 10).reduce((acc, mod) => {
      return acc + calculateModuleECTS(mod);
    }, 0);
    setSelectedPflicht(total);
  }

  function updateSumOfWahlECTS() {
    const total = courses.slice(10, 17).reduce((acc, mod) => {
      sumOfModule(mod);

      return acc + calculateModuleECTS(mod);
    }, 0);
    setSelectedWahl(total);
  }

  // Function to create a unique identifier for each course
  const getCourseKey = (course) => `${course.Kurs}-${course.Typ}`;
  const url = "data2.json";
  // Function to handle when a checkbox is toggled
  const handleCheck = (course, isChecked) => {
    setSelectedCourses((prevSelected) => {
      // Clone the previous state
      const newSelected = { ...prevSelected };
      const courseKey = getCourseKey(course);

      // Add or remove the course ECTS from the state based on whether it's checked
      if (isChecked) {
        newSelected[courseKey] = course.ECTS;
      } else {
        delete newSelected[courseKey];
      }

      return newSelected;
    });
  };

  const handleCheck2 = (course, isChecked, courseType) => {
    handleCheck(course, isChecked);
  };

  // Calculate the sum of selected ECTS
  const sumOfECTS =
    Object.values(selectedCourses).reduce(
      (sum, ects) => sum + Number(ects),
      0
    ) + sumOfFree;

  const handleWahl = (index, name) => {
    const newWahl = [...wahl];
    newWahl[index] = name;
    setWahl(newWahl);
  };

  useEffect(() => {
    updateSumOfPflichtECTS();
  }, [courses, selectedCourses]);

  useEffect(() => {
    updateSumOfWahlECTS();
  }, [courses, selectedCourses]);
  useEffect(() => {
    async function fetchData() {
      const res = await fetch(url);
      const data = await res.json();
      setLoading(false);
      setCourses(data.modules);
    }
    fetchData();
  }, []);

  return (
    <div className="App bg-dark text-white">
      <div className="header">
        <Row>
          <Col xs={4}>Kurs</Col>
          <Col xs={1}>
            <h2>Typ</h2>
          </Col>
          <Col xs={2}>
            <h2>ECTS</h2>
          </Col>
          <Col xs={2}>
            <h2>Semester</h2>
          </Col>
          <Col>
            <h2>Erledigt</h2>
          </Col>
        </Row>
      </div>
      <br></br>

      <div className="module text-center text-dark bg-light">
        <h3>Pflicht</h3>
      </div>
      <div className="total">
        Total ECTS
        <br></br>
        <ProgressBar variant="success" now={(sumOfECTS / totalects) * 100} />
        {sumOfECTS}
        <ProgressBar
          striped
          variant="danger"
          now={100 - (sumOfECTS / totalects) * 100}
        />
        {totalects - sumOfECTS}
        <br></br>
        <br></br>
        Pflicht ECTS
        <br></br>
        <ProgressBar
          variant="success"
          now={(selectedPflicht / totalpflicht) * 100}
        />
        {selectedPflicht}
        <ProgressBar
          striped
          variant="danger"
          now={100 - (selectedPflicht / totalpflicht) * 100}
        />
        {totalpflicht - selectedPflicht}
        <br></br>
        <br></br>
        Wahl ECTS
        <br></br>
        <ProgressBar variant="success" now={(selectedWahl / totalwhal) * 100} />
        {selectedWahl}
        <ProgressBar
          striped
          variant="danger"
          now={100 - (selectedWahl / totalwhal) * 100}
        />
        {totalwhal - selectedWahl}
        <br></br>
        <br></br>
        {wahl.map((w, idx) => {
          return (
            <div>
              {w.label}
              <br></br>
              <ProgressBar className="progbar">
                <ProgressBar variant="success" now={(w.value / 8) * 100} />
                <ProgressBar
                  striped
                  variant="danger"
                  now={100 - (w.value / 8) * 100}
                />
              </ProgressBar>
            </div>
          );
        })}
      </div>
      {courses.slice(0, 10).map((mod, index) => {
        const isModuleSelected =
          selectedModules[mod.moduleName] || areAllModuleCoursesSelected(mod);
        const moduleECTS = calculateModuleECTS(mod);

        return (
          <div key={`module-${index}`}>
            <Row className="module">
              <Col>
                <h3>{mod.moduleName} </h3>
              </Col>
              <Col xs={6}>
                <h3 className="text-center">
                  <ProgressBar className="progbar">
                    <ProgressBar
                      variant="success"
                      now={(moduleECTS / mod.total) * 100}
                      label={moduleECTS}
                    />
                    <ProgressBar
                      striped
                      variant="danger"
                      now={100 - (moduleECTS / mod.total) * 100}
                      label={mod.total - moduleECTS}
                      key={1}
                    />
                  </ProgressBar>
                  ECTS
                </h3>
              </Col>
              <Col>
                <h3>
                  <Form.Check
                    checked={isModuleSelected}
                    onChange={(e) => handleModuleCheck(mod, e.target.checked)}
                  />
                </h3>
              </Col>
            </Row>

            {mod.courses.map((modd, idx) => {
              const courseKey = getCourseKey(modd);
              const isChecked = selectedCourses.hasOwnProperty(courseKey);
              const rowClass = isChecked ? "selectedRow" : "";
              const courseType = courseTypes[courseKey] || "pflicht"; // Default type if not

              return (
                <div className={rowClass + " pflicht"} key={courseKey}>
                  <Row>
                    <Col xs={4}>{modd.Kurs}</Col>
                    <Col xs={1}>{modd.Typ}</Col>
                    <Col xs={2}>{modd.ECTS}</Col>
                    <Col xs={2}>{modd.Semester}</Col>
                    <Col>
                      {/* <Popup trigger={<p>pflicht</p>}>pflicht</Popup> */}

                      <Form.Check>
                        <Form.Label>
                          <Popup
                            ref={ref}
                            trigger={<div>{courseType}</div>}
                            modal
                          >
                            <div>
                              <Button
                                size="sm"
                                onClick={() =>
                                  handleTypeChange(courseKey, "pflicht")
                                }
                              >
                                pflicht
                              </Button>
                              <br></br>
                              <br></br>

                              <Button
                                size="sm"
                                onClick={() =>
                                  handleTypeChange(courseKey, "Frei")
                                }
                              >
                                Frei
                              </Button>
                              <br></br>
                              <br></br>

                              <Button
                                size="sm"
                                onClick={() =>
                                  handleTypeChange(courseKey, "Individuelles")
                                }
                              >
                                Individuelles{" "}
                              </Button>
                            </div>
                          </Popup>
                        </Form.Label>

                        <Form.Check.Input
                          type="checkbox"
                          className="fcheck"
                          checked={isChecked}
                          onChange={(e) =>
                            handleCheck2(modd, e.target.checked, courseType)
                          }
                        />
                      </Form.Check>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        );
      })}
      <br></br>
      <div className="module text-center text-dark bg-light">
        <h3>Wahl</h3>
      </div>

      {courses.slice(10, 17).map((mod, index) => {
        const isModuleSelected =
          selectedModules[mod.moduleName] || areAllModuleCoursesSelected(mod);
        const moduleECTS = calculateModuleECTS(mod);

        return (
          <div key={`module-${index}`}>
            <Row className="module">
              <Col>
                <h3>{mod.moduleName} </h3>
              </Col>
              <Col xs={6}>
                <h3 className="text-center">
                  <ProgressBar className="progbar">
                    <ProgressBar
                      variant="success"
                      now={(moduleECTS / mod.should) * 100}
                      label={moduleECTS}
                    />
                    <ProgressBar
                      striped
                      variant="danger"
                      now={100 - (moduleECTS / mod.should) * 100}
                      label={mod.should - moduleECTS}
                      key={1}
                    />
                  </ProgressBar>
                  ECTS
                </h3>
              </Col>
              <Col>
                <Form.Check
                  checked={isModuleSelected}
                  onChange={(e) => handleModuleCheck(mod, e.target.checked)}
                />
              </Col>
            </Row>

            {mod.courses.map((modd, idx) => {
              const courseKey = getCourseKey(modd);
              const isChecked = selectedCourses.hasOwnProperty(courseKey);
              const rowClass = isChecked ? "selectedRow" : "";
              {
              }
              return (
                <div className={rowClass + " pflicht"} key={courseKey}>
                  <Row>
                    <Col xs={4}>{modd.Kurs}</Col>
                    <Col>{modd.Typ}</Col>
                    <Col>{modd.ECTS}</Col>
                    <Col>{modd.Semester}</Col>
                    <Col>
                      <Form.Check
                        className="fcheck"
                        checked={isChecked}
                        onChange={(e) => handleCheck(modd, e.target.checked)}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        );
      })}
      <br></br>
      <Row className="module">
        <Col>
          <h3>Freie Wahlfächer</h3>
        </Col>
        <Col xs={6}>
          <h3 className="text-center">
            <ProgressBar className="progbar">
              <ProgressBar
                variant="success"
                now={(sumOfFree / totalfree) * 100}
                label={sumOfFree}
              />
              <ProgressBar
                striped
                variant="danger"
                now={100 - (sumOfFree / totalfree) * 100}
                label={totalfree - sumOfFree}
              />
            </ProgressBar>
            ECTS
          </h3>
        </Col>
        <Col></Col>
      </Row>

      {/*  <div className="module text-center text-dark bg-light">
        <h3>Freie Wahlfächer</h3>
      </div> */}
      {freeCourses.slice(1, freeCourses.length).map((modd, idx) => {
        const courseKey = getCourseKey(modd);

        const isChecked = selectedCourses.hasOwnProperty(courseKey);

        const rowClass = isChecked ? "selectedRow" : "";

        return (
          <div className={rowClass + " pflicht"} key={courseKey}>
            <Row>
              <Col xs={4}>{modd.Kurs}</Col>
              <Col>{modd.Typ}</Col>
              <Col>{modd.ECTS}</Col>
              <Col>{modd.Semester}</Col>
              <Col>
                <Button size="sm">Delete</Button>
              </Col>
            </Row>
          </div>
        );
      })}
      <br></br>

      <Popup
        ref={ref}
        trigger={
          <div className="addbtn d-grid gap-2">
            <Button variant="secondary">+</Button>
          </div>
        }
        modal
      >
        <h1 className="text-white">
          <Form.Control
            size="sm"
            type="text"
            placeholder="Kurs"
            ref={kursRef}
          />
          <Form.Control size="sm" type="text" placeholder="Typ" ref={typRef} />
          <Form.Control
            size="sm"
            type="text"
            placeholder="Ects"
            ref={ectsRef}
          />
          <Form.Control
            size="sm"
            type="text"
            placeholder="Semester"
            ref={semesterRef}
          />
          <br />
          <Button onClick={handleAddCourse}>ADD</Button>
        </h1>
      </Popup>

      <br></br>

      <br></br>
      <br></br>
    </div>
  );
}

export default App;
